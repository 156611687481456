/* Text Elements */

h1 {
  font-size: 24px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h1.negative {
  color: #fff;
}

h1.Heading1-same-size.negative {
  min-width: 112px;
  min-height: auto;
  width: auto;
}

h1.Heading2 {
  margin-bottom: 0;
}

h1.Heading1-same-size {
  width: auto;
  font-size: 32px;
}

h1 span.heading-text-1 {
  color: #fff;
}

h1 span.heading-text-3 {
  color: #f2f3a5;
}

h1 span.heading-text-4 {
  color: #f2f3a5;
  font-size: 2.5rem;
}

h1 span.heading-text-5 {
  color: #f2f3a5;
}

h1 span.heading-text-6 {
  font-size: 2.5rem;
}

h1 span.co-funded.heading-text-1 {
  color: #f2f3a5;
  font-size: 2.5rem;
}

h1.Heading-kat {
  font-size: 45px;
}

h1 span.heading-text-11 {
  color: #f2f3a5;
  font-size: 3.75rem;
}

h2.h2 {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 22px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h3.navbar-brand {
  margin-top: 8px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

h3 span.heading-text-2 {
  color: #000;
  font-weight: 700;
  font-size: 1rem;
}

h3.nav-col.navbar-brand {
  color: #000;
}

h3.h3 {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h3.h3.index {
  text-align: left;
}

h5 span.heading-text-8 {
  color: #fff;
  font-weight: 400;
  font-size: 1.375rem;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h5 span.heading-text-7 {
  color: #fff;
  font-weight: 400;
  font-size: 1.375rem;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h5 span.heading-text-9 {
  color: #fff;
  font-weight: 400;
  font-size: 1.375rem;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h5.h5 {
  margin-bottom: 10px;
  max-width: none;
  max-height: none;
}

h5 {
  font-size: 22px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

h5.crew.h5 {
  margin-bottom: 0;
  max-height: 1px;
}

h5.quote-name {
  margin-left: 0;
  padding-right: 50px;
  padding-left: 0;
  text-align: center;
}

h5.quote-name.right {
  padding-right: 0;
  padding-left: 0;
}

h5.left.quote-name {
  padding-right: 0;
}

h5 span.heading-text-10 {
  font-style: normal;
}

h5.quote-name-episodes {
  margin-top: 40px;
  text-align: center;
}

h5.heading-1 {
  margin-bottom: 20px;
}

h6 {
  margin-bottom: 0;
  color: #000;
  font-weight: 700;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 1.4;
}

p.paragraph span.paragraph-text-1 {
  color: #fff;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

p.paragraph span.paragraph-text-2 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-3 {
  font-weight: 700;
}

p.paragraph.text-footer {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 20px;
  text-align: center;
}

p.paragraph a.paragraph-text-5 {
  color: #fff;
}

p.paragraph {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

p.paragraph.main-text-ep {
  margin-right: 0;
  margin-bottom: 0;
}

p.paragraph.size {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
}

p.paragraph span.ingress-about {
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 1.7;
}

p.paragraph span.ingress-about.negativ {
  color: #fff;
}

p.paragraph.card-text {
  margin-bottom: 16px;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

p.paragraph.main-text_about {
  margin-bottom: 16px;
}

p.paragraph span.paragraph-text-4 {
  color: #fff;
}

p.paragraph a.paragraph-text-7 {
  color: #000;
}

p.paragraph span.paragraph-text-6 {
  font-size: 1.375rem;
}

p.paragraph a.paragraph-text-8 {
  color: #000;
}

p.paragraph a.paragraph-text-9 {
  color: #000;
}

p.paragraph a.paragraph-text-10 {
  color: #000;
}

p.paragraph a.paragraph-text-11 {
  color: #000;
}

p.paragraph a.paragraph-text-12 {
  color: #000;
  font-weight: 700;
}

p.paragraph a.paragraph-text-14 {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

p.paragraph span.paragraph-text-13 {
  font-weight: 700;
}

p.paragraph.text-worksheet {
  padding-top: 3px;
  border-color: transparent;
  color: #000;
  font-weight: 700;
}

p.paragraph.ingress.main-text_about.overview {
  margin-top: 0;
  margin-bottom: 0;
}

p.paragraph span.ingress-about.kat.negativ {
  font-size: 1.25rem;
  line-height: .97;
}

p.paragraph.paragraph-1 {
  margin-top: 20px;
}

p.paragraph.index.main-text_about {
  text-align: left;
}

p.paragraph.hero-tagline.index.size {
  margin-bottom: 0;
}

p.paragraph span.paragraph-text-15 {
  font-weight: 700;
}

p.paragraph a.paragraph-text-5:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-7:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-8:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-9:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-10:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-11:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-12:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-14:hover {
  text-decoration: underline;
}

p.paragraph.text-worksheet:hover {
  border-color: transparent;
  color: #000;
  text-decoration: underline;
}

ul.unorder-list.unordered-list-1 {
  padding-left: 20px;
  list-style-position: outside;
  list-style-type: square;
}

dl.description-list.list-sources {
  margin-right: 40px;
}

dl.description-list.list-sources.right {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.list-sources.middle {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.left.list-sources {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.list-sources.one {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.list-sources.two {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.list-sources.three {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.four.list-sources {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

dl.description-list.five.list-sources {
  visibility: visible;
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100%;
}

span.text-element {
  color: #000;
  font-size: 16px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 1.5;
}

blockquote.quote-text {
  margin: 0;
  padding-top: 40px;
  padding-right: 0;
  padding-bottom: 0;
  min-width: 0;
  min-height: auto;
  max-width: none;
  height: auto;
  border-top-width: .125rem;
  border-top-style: solid;
  border-right-width: .625rem;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  justify-self: auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  background-blend-mode: normal;
}

blockquote {
  text-align: center;
  font-size: 40px;
  font-family: Baskerville, 'Baskerville Old Face', 'Hoefler Text', Garamond, 'Times New Roman', serif;
}

blockquote p{ /* Edit/added ANDREAS */
  font-family: Baskerville, 'Baskerville Old Face', 'Hoefler Text', Garamond, 'Times New Roman', serif;
}

a.link-text.nav-link {
  margin-right: 4px;
  margin-left: 4px;
  padding: 5px 8px 8px;
  text-align: center;
}

a.link-text.nav-col.nav-link {
  color: rgba(0, 0, 0, .75);
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

a.link-text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 2.4;
}

a.link-text.dropdown-item {
  position: static;
  display: block;
  float: none;
  clear: none;
  margin-right: 0;
  margin-left: 0;
  padding-left: 10px;
  min-width: 120px;
  max-width: 0;
  background-color: transparent;
  text-align: left;
  font-size: 24px;

  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
          align-self: center;
  grid-area: auto/ auto / auto / auto;
}

a.link-text.nav-col.nav-link.nav-size {
  font-size: 24px;
  line-height: 2.4;
}

a.link-text.nav-size {
  text-align: center;
  font-size: 24px;
}

a.link-text.dropdown-item.start {
  margin-top: 0;
}

a.link-text.nav-col.nav-link:visited {
  color: rgba(0, 0, 0, .75);
}

a.link-text.nav-col.nav-link:focus {
  color: rgba(0, 0, 0, .75);
}

a.link-text.nav-col.nav-link:hover {
  color: #fd4e00;
}

a.link-text.dropdown-item:hover {
  background-color: transparent;
  color: #fd4e00;
}

a.link-text.nav-col.nav-link:active {
  color: #000;
  text-decoration: underline;
  text-indent: 0;
  font-weight: 900;
  font-size: 16px;
}

a.link-text.nav-col.nav-link.nav-size:active {
  color: #fd4e00;
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
}

/* Interaction Elements */

a.link-button.btn-lecture {
  position: static;
  display: block;
  overflow: visible;
  margin-top: 10px;
  margin-right: 10px;
  padding-top: 3px;
  padding-bottom: 6px;
  padding-left: 60px;
  min-width: 308px;
  min-height: 50px;
  max-width: 420px;
  max-height: 50px;
  height: auto;
  background-image: url('../1.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: repeat;
  color: #000;
  vertical-align: middle;
  text-align: left;
  font-weight: 700;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 20px;

  background-blend-mode: normal;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  grid-area: auto / auto / auto / auto;
}

a.link-button.btn-fill.btn-lecture {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 1px;
  max-height: 1px;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-fill {
  min-height: 10px;
  max-height: 10px;
}

a.link-button.btn-1.btn-lecture {
  background-image: url('../1.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-2.btn-lecture {
  background-image: url('../2.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-3.btn-lecture {
  background-image: url('../3.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-4.btn-lecture {
  background-image: url('../4.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-5.btn-lecture {
  background-image: url('../5.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-6.btn-lecture {
  background-image: url('../6.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-7.btn-lecture {
  background-image: url('../7.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-8.btn-lecture {
  background-image: url('../8.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-9.btn-lecture {
  background-image: url('../9.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-11.btn-lecture {
  background-image: url('../11.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-12.btn-lecture {
  background-image: url('../12.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-10.btn-lecture {
  background-image: url('../10.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn-14.btn-lecture {
  background-image: url('../14.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn.btn-secondary.dropdown-toggle {
  margin-right: auto;
  margin-left: auto;
  max-width: none;
  width: 240px;
  height: 60px;
  border-width: 0;
  border-color: transparent;
  background-color: transparent;
  background-image: url('../6_languages.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 40%;
  background-repeat: no-repeat;
  color: transparent;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  background-blend-mode: normal;
}

a.link-button {
  border-style: none;
  border-color: transparent;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

a.link-button.btn.btn-episodes {
  padding-right: 0;
  padding-left: 0;
  width: 40%;
  height: 50px;
  border-radius: 0;
  background-color: transparent;
  color: #000;
  line-height: 36px;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start {
  margin-right: auto;
  margin-left: auto;
  max-width: none;
  width: 240px;
  height: 60px;
  border-width: 0;
  border-color: transparent;
  background-color: transparent;
  background-image: url('../6_languages.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 40%;
  background-repeat: no-repeat;
  color: transparent;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  background-blend-mode: normal;
}

a.link-button.btn-13.btn-lecture {
  background-image: url('../13.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn.btn-1002 {
  margin: 30px 0 10px;
  padding-right: 40px;
  padding-left: 40px;
  height: 50px;
  /* background-color: rgba(255, 255, 255, .6); Edit ANDREAS */
  color: #000;
  text-align: center;
  font-size: 22px;
  line-height: 39px;
}

a.link-button font.button-link-text-1 {
 /* color: #fff;Edit ANDREAS */
  font-size: 1.125rem;
}

a.link-button.btn.btn-1002.welcome {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #9176b4;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

a.link-button.btn.btn-left-1004 {
  max-width: 34px;
  max-height: 34px;
  width: 34px;
  height: 34px;
  background-image: url('../Arrow-L.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn.btn-right-1004 {
  max-width: 34px;
  max-height: 34px;
  width: 34px;
  height: 34px;
  background-image: url('../Arrow-R.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

a.link-button.btn.btn-1002.overview {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #dc4200;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

a.link-button.btn.btn-1002.evaluation {
  margin-top: 70px;
  margin-right: 20px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #714987;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

a.link-button span.button-link-text-3 {
  color: #fff;
}

a.link-button span.button-link-text-4 {
  color: #fff;
}

a.link-button span.button-link-text-5 {
  color: #fff;
}

a.link-button span.button-link-text-6 {
  color: #fff;
}

a.link-button.btn.btn-1002.register {
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #485a89;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

/* edit ANDREAS START */
button.link-button.btn.btn-1002.register {
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #485a89;
  text-align: center;
  background-image:none;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  border-radius: 0.25rem;
}
button.link-button.btn.btn-1002 {
  margin: 30px 0 10px;
  padding-right: 40px;
  padding-left: 40px;
  height: 50px;
  /* background-color: rgba(255, 255, 255, .6); */
  color: #fff;
  text-align: center;
  font-size: 22px;
  line-height: 39px;
}
button.link-button.btn.btn-1002:hover {
  /* background-color: #fff; */
  opacity: .8;
}
/* edit ANDREAS END */

a.link-button.about.btn.btn-1002 {
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  background-color: #dc4200;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

a.link-button.btn.btn-secondary.dropdown-toggle.outofview {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start.outofview {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn.btn-secondary.dropdown-toggle:visited {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start:visited {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn.btn-secondary.dropdown-toggle:focus {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start:focus {
  border-style: none;
  border-color: transparent;
}

a.link-button.btn:hover {
  background-color: transparent;
  white-space: nowrap;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

a.link-button.btn.btn-secondary.dropdown-toggle:hover {
  border-style: none;
  border-color: transparent;
  background-color: transparent;
}

a.link-button.btn.btn-episodes:hover {
  background-color: rgba(120, 120, 120, .8);
  color: #fff;
  text-align: center;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start:hover {
  border-style: none;
  border-color: transparent;
  background-color: transparent;
}

a.link-button.btn.btn-1002:hover {
  border-color: #000;
  border-radius: 4px;
  /* background-color: #fff; edit ANDREAS*/
  color: #000;
  font-size: 22px;
  line-height: 39px;
}

a.link-button span.button-link-text-2:hover {
  color: #fff;
}

a.link-button.btn.btn-episodes:active {
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
}

a.link-button.btn.btn-secondary.dropdown-toggle:active {
  padding-top: 20px;
  padding-bottom: 2px;
  height: 40px;
  border-style: none;
  border-color: transparent;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 80%;
}

a.link-button.btn:active {
  border-color: transparent;
}

a.link-button:active {
  border-color: transparent;
}

a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start:active {
  padding-top: 20px;
  padding-bottom: 2px;
  height: 40px;
  border-style: none;
  border-color: transparent;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 80%;
}

a.link-button span.button-link-text-2:active {
  color: #fff;
}

form.form-container.form-container-1 {
  padding: 20px 0;
  max-width: 540px;
  width: 100%;
}

input[type=text].input-2 {
  margin-right: 4%;
  width: 48%;
}

input[type=text].input-1 {
  margin-right: 0;
  width: 48%;
}

input[type=text].input-4 {
  width: 100%;
}

input[type=text].input-3 {
  width: 100%;
}

input[type=email].email-1 {
  width: 100%;
}

label.radio.radio-button-1 {
  width: 30%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

label.radio.radio-button-2 {
  width: 30%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

label.radio.radio-button-3 {
  width: 30%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

textarea.textarea-1 {
  width: 100%;
  height: 70px;
}

label.label.label-1 {
  margin-top: 20px;
  margin-bottom: 4px;
  width: 100%;
  font-weight: 700;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

label.label.label-1.name {
  margin-right: 4%;
  width: 48%;
}

label.label.normal {
  font-weight: 400;
}

label.label.label-1.surname {
  margin-right: 0;
  width: 48%;
}

/* Layout Elements */

body.body-1 {
  position: static;
  min-height: auto;
  background-color: #ebeae6;

  grid-auto-rows: 1px;
  grid-auto-columns: 1px;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
}

.link-container.link-container-worksheet {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin-bottom: 0;
  padding: 10px 20px 10px 10px;
  min-width: 100px;
  min-height: auto;
  width: 2902px;
  height: 50px;
  border-radius: 4px;
  background-color: #e1e0dd;
}

.link-container.ext-link_title_description {
  margin-bottom: 16px;
}

.link-container.all.link-container-worksheet {
  padding-right: 0;
  padding-left: 5px;
  width: 100%;
}

.link-container.link-container-worksheet.one {
  padding-left: 5px;
  width: 100%;
}

.link-container.link-container-worksheet.one:hover {
  background-color: #c8c7c5;
}

.link-container.all.link-container-worksheet:hover {
  background-color: #c8c7c5;
}

.container-grid.footer-container-wrap {
  position: static;
  display: block;
  -ms-flex-order: 0;
  float: none;
  clear: none;
  margin-top: 60px;
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto;
  height: auto;
  background-color: #282828;
  text-align: center;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  grid-area: auto / auto /auto/ auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
          order: 0;
}

.container-grid.navbar.navbar-dark.navbar-expand-lg {
  margin-top: 0;
  margin-bottom: 0;
  padding: 4px 16px 4px 15px;
  background-color: #fff;
}

a.responsive-picture.picture-link-1 {
  margin-top: 4px;
  margin-right: 4px;
  margin-bottom: 6px;
  max-width: 50px;
  width: auto;
  background-image: url('../O.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.container-main-text {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin-top: 30px;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  min-width: 100%;
  max-width: 660px;
  width: 100%;

  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
}

.container-grid.wrap-main-text {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  margin-top: 0;
  margin-bottom: 20px;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
     -moz-box-orient: vertical;
     -moz-box-direction: reverse;
}

.container-grid.con-video-bgr {
  margin-top: 20px;
  background-image: url('../Language-TEST3.jpg');
  background-attachment: fixed;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.con-video-size {
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;
  max-height: 432px;
}

.container-grid.nav-video {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 92%;
  max-height: none;
  height: 50px;

  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  justify-self: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

button.btn {
  display: inline-block;
  border-radius: 0;
  background-image: url('../Arrow-L.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;

  justify-self: auto;
  background-blend-mode: normal;
}

.container-grid.wrap-nav-video {
  max-width: none;
  max-height: none;
  height: 50px;
  background-color: rgba(0, 0, 0, .8);
}

.container-grid.wrap-worksheet {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: start;
  float: none;
  clear: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 0;
  padding-left: 0;
  min-width: 100px;
  min-height: 0;
  max-height: 50px;
  width: 290px;
  height: 50px;
  text-align: left;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
  grid-area: auto/ auto /auto/ auto;
  justify-self: auto;
}

.rule.rule-1 {
  margin-top: 30px;
  width: 96%;
}

.rule.rule-2 {
  margin-bottom: 15px;
  width: 96%;
}

.rule.rule-2 hr {
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, .2);
}

.rule.rule-1 hr {
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, .2);
}

div.responsive-picture.picture-1 {
  margin: 40px auto 0;
  padding: 15px 20px 10px;
  min-height: 75px;
  max-width: 290px;
  max-height: 75px;
  width: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  background-blend-mode: normal;
}

button.btn.button.navbar-toggler.navbar-toggler-icon {
  min-width: 0;
  max-width: 40px;
  max-height: 40px;
  width: 36px;
  height: 36px;
  background-color: transparent;
  background-image: url('../hamburgermenu.png');
  background-size: contain;
}

.container-grid.wrap-title-episode {
  margin-top: 20px;
}

.container-grid.title-size {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  max-width: 798px;
}

.container-grid.title-number {
  position: static;
  display: inline-block;
  -ms-flex-align: start;
  margin-right: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 4px;
  background-image: url('../1.png');
  background-attachment: local;
  background-position: center top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  justify-self: auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  background-blend-mode: normal;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  grid-area: auto / auto / auto / auto;
}

.container-grid.main-text {
  margin: 10px 0 16px;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

div.responsive-picture.picture-worksheet {
  margin-right: 4px;
  padding: 3px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  width: auto;
}

.container-grid.external-links {
  margin-top: 20px;
  min-width: 100%;
}

.container-grid.dropdown-menu {
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 100%;
  max-width: 100%;
  border-width: 0;
  border-style: none;
  border-radius: 0;
  background-color: rgba(255, 255, 255, .95);
}

.container-grid.dropup {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-bottom: 0;
  padding-top: 20px;
  min-height: 80px;
  background-color: #282828;
  text-align: left;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

div.responsive-picture.picture-2 {
  float: none;
  clear: none;
  margin-right: 0;
  margin-left: 0;
  min-width: 30px;
  max-width: 30px;
  width: auto;
  background-image: url('../6_languages_GER.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: contain;
  background-repeat: no-repeat;

  -webkit-align-self: center;
  -ms-flex-item-align: center;
          align-self: center;
  justify-self: auto;
  background-blend-mode: normal;
}

.container-grid.wrap-one-language {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: center;
  -ms-flex-align: start;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  grid-area: auto/ auto /auto/ auto;
  justify-self: center;
}

.container-grid.wrap-main-text-episodes {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  margin-top: 0;
  margin-bottom: 20px;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
     -moz-box-orient: vertical;
     -moz-box-direction: reverse;
}

.container-grid.container-main-text-episodes {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin-top: 60px;
  margin-right: 10px;
  margin-left: 15px;
  padding-right: 0;
  max-width: none;

  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.placeholder-hero {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-right: 0;
  padding-top: 50px;
  padding-bottom: 480px;
  padding-left: 0;
  min-height: auto;
  max-height: none;
  height: auto;
  background-color: #bf4f10;
  background-image: url('../bgr01-E.jpg');
  background-attachment: scroll;
  background-position: center center;
  background-clip: padding-box;
  background-origin: padding-box;
  background-size: auto 100%;
  background-repeat: no-repeat;
  text-align: center;

  background-blend-mode: normal;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.container-grid.main-text-episodes {
  margin-top: 10px;
  margin-bottom: 16px;
}

.container-grid.footer-no-margin {
  margin-top: 0;
  width: 100%;
}

.container-grid.dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding-top: 20px;
  min-height: 80px;
  text-align: left;

  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.container-grid.dropdown-index-mod {
  padding-top: 20px;
  padding-bottom: 20px;
}

.container-grid.footer-container-wrap.footer-no-margin {
  margin-top: 0;
}

.container-grid.background.wrap-main-text {
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: center;
  margin-right: 0;
  margin-bottom: 0;
  padding-right: 15px;
  padding-left: 15px;
  min-width: 0;
  width: 100%;
  background-color: rgba(208, 207, 204, .8);

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.container-grid.div-headings {
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
  min-width: 100%;
  min-height: auto;
  max-height: none;
  width: auto;
}

div.responsive-video.video-platform-1 {
  min-width: 40%;
  max-width: none;
  width: auto;
}

.container-grid.wrap-video-ep1 {
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 30px;
  width: 100%;
}

.container-grid.div-headings.ep1 {
  margin-top: 20px;
  margin-bottom: 0;
}

.container-grid.ep13.title-number {
  background-image: url('../13.png');
}

.container-grid.ep14.title-number {
  background-image: url('../14.png');
}

.container-grid.partners.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-align: start;
  margin-bottom: 0;
  padding: 60px 15px 14px;
  background-color: #64835e;

  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

dd.negative-text {
  visibility: visible;
  margin-bottom: 24px;
  color: #fff;
}

dt.negative-text {
  color: #fff;
}

.container-grid.wrap-sources {
  padding: 60px 15px 50px;
  background-color: rgba(0, 0, 0, .65);
}

.container-grid.outer-wrap-sources {
  display: block;
}

.container-grid.container-main-text-about {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin-top: 60px;
  margin-right: 15px;
  margin-left: 15px;
  padding-right: 0;
  padding-left: 0;
  min-width: 0;
  max-width: none;

  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.background-about.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 15px 70px;
  background-color: #5e8358;
  background-image: url('../bgr011-D.jpg');
  background-attachment: scroll;
  background-position: center top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 100%;
  background-repeat: no-repeat;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  background-blend-mode: normal;
}

.container-grid.container-main-text-about.main {
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0;
  padding: 15px;
  border-radius: 20px;
  background-color: #fff;

  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
}

.container-grid.main-text-about {
  margin-right: 0;
  margin-left: 0;
}

.container-grid.container-main-text-about.ingress {
  margin-right: -10px;
  margin-left: 0;
  padding-right: 0;
}

.container-grid.card.card-flex-header {
  position: static;
  display: inline-block;
  float: none;
  clear: none;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 10px;
  min-width: 100%;
  max-width: 100%;
  width: auto;

  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  justify-self: auto;
}

.rule.rule-partners {
  margin-top: 0;
  width: 100%;
}

.rule.rule-partners hr {
  border-top-width: 1px;
  border-top-color: #f2f3a5;
}

.container-grid.card.card-flex-header.card-partners {
  margin-bottom: 0;
  padding: 15px;
  min-width: 0;
  max-width: none;
  border-width: 0;
  border-style: none;
  border-radius: 20px;
}

.container-grid.container-main-text.partners {
  display: block;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin-top: 50px;
  margin-right: 0;
  margin-left: 0;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
}

.container-grid.wrap-all-partners {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.container-grid.wrap-logo {
  margin-bottom: 0;
  min-width: 200px;
  width: 200px;
  height: 80px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  background-image: url('../VGR_logo_sq.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 140% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.wrap-card-and-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-align: center;
  margin-bottom: 50px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.container-grid.main-text-co-funded {
  margin-top: 0;
}

.container-grid.co-funder {
  margin-top: 0;
}

.container-grid.co-funder.container-main-text.partners {
  margin-top: 0;
}

.container-grid.co-funded.div-headings {
  margin-bottom: 20px;
}

.container-grid.creative-europe.wrap-logo {
  background-image: url('../EU-logga_600.png');
  background-size: 90% auto;
}

.container-grid.reversed.theatre-forum.wrap-logo {
  background-image: url('../Theatre_Forum_logo_sq.png');
  background-size: 130% auto;
}

.container-grid.agencia-andaluza.wrap-logo {
  background-image: url('../Spain-logo_sq.png');
}

.container-grid.background.video.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: start;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #5f4a82;

  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
}

.container-grid.wrap-video-about {
  margin-top: 60px;
  padding-top: 0;
  min-width: 100%;
}

.container-grid.wrap-quote {
  margin: 50px 0 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 30px;
  min-width: 40%;
  max-width: 90%;
}

.container-grid.wrap-books {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

.container-grid.other-books {
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

.container-grid.reference {
  margin-bottom: 40px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

dd a.list-description-text-1 {
  color: #fff;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dd a.list-description-text-2 {
  color: #fff;
  text-decoration: none;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dd span.list-description-text-3 {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dd a.list-description-text-4 {
  color: #fff;
}

dd span.list-description-text-5 {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dd a.list-description-text-6 {
  color: #fff;
}

dd {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dt {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.container-grid.wrap-participants {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 60px 15px 20px;
  background-color: #803280;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.participants-one {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 900px;
}

.container-grid.participants-four {
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

.container-grid.participants-three {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

.container-grid.participants-two {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

.container-grid.participants-five {
  visibility: visible;
  float: none;
  clear: none;
  margin-right: 0;
  margin-bottom: 60px;
  min-width: 100px;
  max-width: none;
  width: 100%;
}

dd.crew.negative-text {
  margin-bottom: 24px;
}

.container-grid.wrap-two-quotes {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-top: 0;
  margin-bottom: 20px;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.welcome.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 15px 70px;
  background-color: #634886;
  background-image: url('../bgr014-D.jpg');
  background-attachment: scroll;
  background-position: center top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 120%;
  background-repeat: no-repeat;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  background-blend-mode: normal;
}

.container-grid.gdpr.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0 15px 70px;
  background-color: #485a89;
  background-image: url('../bgr08-E.jpg');
  background-attachment: scroll;
  background-position: center top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 100%;
  background-repeat: no-repeat;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  background-blend-mode: normal;
}

.container-grid.ep3.title-number {
  background-image: url('../3.png');
}

.container-grid.ep2.title-number {
  border-radius: 4px;
  background-image: url('../2.png');
}

.container-grid.ep4.title-number {
  border-radius: 4px;
  background-image: url('../4.png');
}

.container-grid.ep5.title-number {
  border-radius: 4px;
  background-image: url('../5.png');
}

.container-grid.ep6.title-number {
  border-radius: 4px;
  background-image: url('../6.png');
}

.container-grid.ep7.title-number {
  border-radius: 4px;
  background-image: url('../7.png');
}

.container-grid.ep8.title-number {
  border-radius: 4px;
  background-image: url('../8.png');
}

.container-grid.ep9.title-number {
  border-radius: 4px;
  background-image: url('../9.png');
}

.container-grid.ep10.title-number {
  border-radius: 4px;
  background-image: url('../10.png');
}

.container-grid.ep11.title-number {
  border-radius: 4px;
  background-image: url('../11.png');
}

.container-grid.ep12.title-number {
  border-radius: 4px;
  background-image: url('../12.png');
}

.container-grid.overview.wrap-main-text {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 15px 70px;
  background-color: #bf4f10;
  background-image: url('../bgr01-E.jpg');
  background-attachment: scroll;
  background-position: center top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto 120%;
  background-repeat: no-repeat;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  background-blend-mode: normal;
}

li.list-item.list-item-1 {
  margin-bottom: 10px;
}

li.list-item.list-item-2 {
  margin-bottom: 10px;
}

li.list-item.list-item-3 {
  margin-bottom: 10px;
}

li.list-item.list-item-4 {
  margin-bottom: 10px;
}

li.list-item.list-item-5 {
  margin-bottom: 10px;
}

li.list-item.list-item-6 {
  margin-bottom: 10px;
}

.container-grid.ep14.wrap-main-text-episodes {
  -ms-flex-pack: start;
  -ms-flex-align: center;
  padding-top: 0;

  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
}

.container-grid.con-video-bgr.ep1 {
  background-color: #595e40;
  background-image: url('../bgr01.jpg');
}

.container-grid.con-video-bgr.ep2 {
  background-color: #313e68;
  background-image: url('../bgr02.jpg');
}

.container-grid.con-video-bgr.ep3 {
  background-color: #426372;
  background-image: url('../bgr03.jpg');
}

.container-grid.con-video-bgr.ep4 {
  background-color: #436781;
  background-image: url('../bgr04.jpg');
}

.container-grid.con-video-bgr.ep5 {
  background-color: #405a59;
  background-image: url('../bgr05.jpg');
}

.container-grid.con-video-bgr.ep6 {
  background-color: #546543;
  background-image: url('../bgr06.jpg');
  background-attachment: fixed;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.con-video-bgr.ep7 {
  background-color: #655929;
  background-image: url('../bgr07.jpg');
}

.container-grid.con-video-bgr.ep8 {
  background-color: #785343;
  background-image: url('../bgr08.jpg');
}

.container-grid.con-video-bgr.ep9 {
  background-color: #93534a;
  background-image: url('../bgr09.jpg');
}

.container-grid.con-video-bgr.ep10 {
  background-color: #4a6345;
  background-image: url('../bgr10.jpg');
}

.container-grid.con-video-bgr.ep11 {
  background-color: #4c6545;
  background-image: url('../bgr11.jpg');
}

.container-grid.con-video-bgr.ep12 {
  background-color: #605d3c;
  background-image: url('../bgr12.jpg');
}

.container-grid.con-video-bgr.ep13 {
  background-color: #42625f;
  background-image: url('../bgr13.jpg');
}

.container-grid.con-video-bgr.ep14 {
  min-height: auto;
  max-height: 432px;
  height: 432px;
  background-color: #503266;
  background-image: url('../bgr14_text.jpg');
}

.container-grid.all-episodes {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin-top: 10px;
  margin-bottom: 50px;
  margin-left: 15px;
  padding-top: 30px;
  padding-right: 10px;
  min-width: 0;
  max-width: none;
  vertical-align: baseline;

  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1;
     -moz-box-flex: 0;
      -ms-flex: 0 1;
          flex: 0 1;
}

.rule.rule-3 {
  margin-top: 20px;
}

.container-grid.footer-container-wrap.no-margin {
  margin-top: 0;
}

.container-grid.all.wrap-worksheet {
  margin-top: 0;
  margin-bottom: 0;
  width: 96%;
}

.container-grid.one.wrap-worksheet {
  margin-top: 20px;
  margin-right: 0;
  max-width: none;
  width: 96%;
}

.container-grid.card-block.card-block-flex {
  margin-left: 0;
  padding-left: 0;
}

.container-grid.container-main-text.overview-intro {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  min-width: 0;
  max-width: 540px;
  width: auto;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
}

.container-grid.kat.overview.wrap-main-text {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-align: center;
  padding-top: 30px;
  padding-bottom: 0;
  background-color: #5f4a82;
  background-image: url('../bgr014-D.jpg');
  background-position: left top;
  background-size: auto 220%;

  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.container-grid.kat-wrap {
  margin-right: 30px;
  margin-bottom: 20px;
  min-width: 200px;
  min-height: 200px;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-image: url('../Katarina_0M5A7517.jpg');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: cover;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.wrap-pic-header {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  width: 100%;

  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.main-text-co-funded.overview {
  margin-top: -10px;
}

div.responsive-picture.logo-upper.picture-1 {
  margin-top: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.container-grid.container-1 {
  margin-top: 250px;
}

.container-grid.dropdown-index-mod.dropdown-menu {
  margin-top: 100px;
}

.container-grid.container-main-text-about.index.main {
  margin-right: 10px;
  margin-bottom: 40px;
  margin-left: 10px;
}

.container-grid.drop-bg-color.dropdown {
  padding-top: 0;
}

.container-grid.container-1.index {
  margin-top: 50px;
}

.container-grid.index.placeholder-hero {
  padding-bottom: 150px;
}

.container-grid.dropdown-menu:hover {
  border-style: none;
  background-color: rgba(255, 255, 255, .95);
}

dd a.list-description-text-1:hover {
  text-decoration: underline;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

dd a.list-description-text-2:hover {
  text-decoration: underline;
}

dd span.list-description-text-3:hover {
  text-decoration: underline;
}

dd a.list-description-text-4:hover {
  color: #fff;
  text-decoration: underline;
}

dd span.list-description-text-5:hover {
  text-decoration: underline;
}

dd a.list-description-text-6:hover {
  color: #fff;
  text-decoration: underline;
}

.container-grid.one.wrap-worksheet:hover {
  background-color: transparent;
}

.container-grid.dropdown-menu:active {
  margin-bottom: 30px;
}

@media screen and (min-width: 36rem) {
  /* Text Elements */

  h1 {
    font-size: 34px;
  }

  h1.Heading1-same-size.negative {
    float: none;
    clear: none;
    min-width: 112px;
    width: auto;
  }

  h1 span.heading-text-3 {
    color: #f2f3a5;
  }

  p.paragraph.text-footer {
    margin-right: auto;
    margin-left: auto;
  }

  p.paragraph.ingress.main-text_about.overview {
    margin-top: 0;
  }

  dl.description-list.list-sources {
    max-width: 46%;
    width: auto;
  }

  dl.description-list.list-sources.right {
    margin-right: 0;
  }

  dl.description-list.list-sources.middle {
    margin-right: 0;
  }

  dl.description-list.left.list-sources {
    margin-bottom: 0;
  }

  dl.description-list.list-sources.one {
    margin-bottom: 0;
  }

  dl.description-list.list-sources.two {
    margin-bottom: 0;
  }

  dl.description-list.list-sources.three {
    margin-bottom: 0;
  }

  dl.description-list.four.list-sources {
    margin-right: 0;
  }

  dl.description-list.five.list-sources {
    margin-right: 0;
  }

  blockquote.quote-text {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
  }

  /* Interaction Elements */

  a.link-button.btn-lecture {
    position: static;
    display: block;
    float: none;
    clear: none;
    margin-left: 0;
    padding-right: 12px;
    padding-left: 60px;
    min-width: 420px;
    max-width: 420px;
    background-attachment: scroll;
    background-position: left top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 0 0;
    background-repeat: no-repeat;
    color: #000;
    vertical-align: middle;
    font-weight: 700;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;

    justify-self: auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
            align-self: auto;
    background-blend-mode: normal;
  }

  a.responsive-picture.picture-link-1 {
    margin-right: 8px;
  }

  .container-grid.main-text {
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .container-grid.container-main-text-episodes {
    max-width: 540px;
  }

  .container-grid.main-text-episodes {
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .container-grid.partners.wrap-main-text {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.div-headings {
    display: block;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-right: auto;
    margin-bottom: 12px;
    margin-left: auto;
    min-width: 100%;
    max-width: none;
    max-height: none;
    width: auto;
    height: auto;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.wrap-sources {
    display: block;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: start;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.outer-wrap-sources {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    margin-right: auto;
    margin-left: auto;
    max-width: 540px;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.container-main-text-about {
    max-width: 540px;
  }

  .container-grid.container-main-text-about.ingress {
    margin-right: 0;
  }

  .rule.rule-partners {
    width: 100%;
  }

  .container-grid.co-funded.div-headings {
    margin-bottom: 30px;
    height: auto;
  }

  .container-grid.wrap-quote {
    max-width: 90%;
  }

  .container-grid.other-books {
    margin-bottom: 40px;
  }

  .container-grid.wrap-books {
    margin-bottom: 40px;
  }

  .container-grid.wrap-participants {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: start;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.participants-one {
    margin-bottom: 40px;
  }

  .container-grid.participants-four {
    margin-bottom: 0;
  }

  .container-grid.participants-three {
    margin-bottom: 40px;
  }

  .container-grid.participants-two {
    margin-bottom: 40px;
  }

  .container-grid.participants-five {
    margin-bottom: 40px;
  }

  .container-grid.all-episodes {
    -ms-flex-pack: center;
    -ms-flex-align: start;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.wrap-video-about {
    min-width: 540px;
    max-width: 540px;
  }

  .container-grid.background.video.wrap-main-text {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.all.wrap-worksheet {
    width: 96%;
  }

  .container-grid.one.wrap-worksheet {
    width: 96%;
  }

  .container-grid.kat.overview.wrap-main-text {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    background-position: center top;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.container-main-text.overview-intro {
    min-width: 540px;
    width: auto;
  }

  .container-grid.main-text-co-funded.overview {
    margin-top: -20px;
  }

  .container-grid.container-main-text {
    min-width: 540px;
    max-width: 540px;
    width: 540px;
  }
}

@media screen and (min-width: 42.375rem) {
  /* Text Elements */

  dl.description-list.list-sources.right {
    margin-right: 0;
  }

  dl.description-list.list-sources.middle {
    margin-right: 0;
  }

  dl.description-list.four.list-sources {
    margin-right: 0;
  }

  dl.description-list.five.list-sources {
    margin-right: 0;
  }

  blockquote.quote-text {
    margin-top: 0;
    padding-top: 40px;
  }

  /* Interaction Elements */

  a.link-button.btn-lecture {
    margin-right: 20px;
    min-width: 420px;
    max-width: 420px;
  }

  .container-grid.container-main-text {
    -ms-flex-pack: start;
    min-width: 540px;
    max-width: 540px;
    width: 540px;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.wrap-main-text {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;

    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
       -moz-box-orient: vertical;
       -moz-box-direction: reverse;
  }

  .container-grid.wrap-nav-video {
    max-height: 50px;
    height: 50px;
  }

  .container-grid.nav-video {
    max-height: 50px;
    height: 50px;
  }

  button.btn.button.navbar-toggler.navbar-toggler-icon {
    visibility: visible;
    width: 36px;
    height: 36px;
  }

  .container-grid.wrap-main-text-episodes {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;

    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
       -moz-box-orient: vertical;
       -moz-box-direction: reverse;
  }

  .container-grid.container-main-text-episodes {
    -ms-flex-pack: start;
    max-width: 540px;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  dt.negative-text {
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
  }

  dd.negative-text {
    color: #fff;
  }

  .container-grid.outer-wrap-sources {
    -ms-flex-pack: start;
    max-width: 540px;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.container-main-text-about {
    -ms-flex-pack: start;
    max-width: 540px;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.card.card-flex-header {
    position: static;
  }

  .container-grid.wrap-logo {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
  }

  .container-grid.wrap-card-and-logo {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 50px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.wrap-all-partners {
    display: block;
  }

  .container-grid.partners.wrap-main-text {
    padding-bottom: 14px;
  }

  .container-grid.wrap-two-quotes {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.all-episodes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: start;
    margin-left: 15px;
    max-width: 1500px;
    max-height: none;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.container-main-text.overview-intro {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
  }
}

@media screen and (min-width: 48rem) {
  /* Text Elements */

  h3.h3.index {
    text-align: left;
  }

  h5.h5 {
    min-width: 0;
    max-height: none;
  }

  h5.crew.h5 {
    margin-bottom: 10px;
    max-height: none;
  }

  h5.left.quote-name {
    padding-right: 50px;
  }

  h5.quote-name.right {
    padding-left: 50px;
  }

  h5.quote-name-episodes {
    padding-right: 70px;
    text-align: right;
  }

  p.paragraph.main-text-ep {
    margin-right: 40px;
  }

  p.paragraph.index.main-text_about {
    text-align: left;
  }

  dl.description-list.left.list-sources {
    width: 375px;
  }

  dl.description-list.list-sources.middle {
    width: 375px;
  }

  dl.description-list.list-sources.one {
    width: 375px;
  }

  dl.description-list.list-sources.two {
    width: 375px;
  }

  dl.description-list.list-sources.three {
    width: 375px;
  }

  dl.description-list.four.list-sources {
    width: 375px;
  }

  dl.description-list.five.list-sources {
    width: 375px;
  }

  blockquote.quote-text {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 50px;
    padding-left: 0;
    min-width: 0;
    max-width: none;
    border-top-width: 0;
    border-right-width: .125rem;
    border-right-style: solid;
  }

  blockquote {
    text-align: right;
  }

  blockquote.quote-text.right {
    margin-left: 0;
    padding-right: 0;
    padding-left: 50px;
    border-style: none;
    text-align: center;
  }

  blockquote.left.quote-text {
    text-align: center;
  }

  a.link-text.nav-size {
    font-size: 16px;
  }

  a.link-text.nav-col.nav-link.nav-size {
    font-size: 16px;
  }

  a.link-text.nav-col.nav-link.nav-size:active {
    font-size: 16px;
  }

  /* Interaction Elements */

  a.link-button.btn-lecture {
    min-width: 338px;
    max-width: 338px;
  }

  .container-grid.container-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: start;
    margin-top: 40px;
    margin-right: 0;
    margin-left: 0;
    min-width: 0;
    max-width: 410px;
    width: 50%;

    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.collapse.navbar-collapse {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    visibility: visible;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: center;
    max-height: none;

    -webkit-box-flex: 1;
    -webkit-flex: auto;
       -moz-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.collapse {
    display: inline-block;
  }

  li.list-item-container.nav-item {
    min-width: 0;
  }

  ul.list-container.navbar-nav {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  button.btn.button.navbar-toggler.navbar-toggler-icon {
    visibility: hidden;
  }

  .container-grid.main-text {
    margin-top: 50px;
    margin-right: 40px;
    margin-bottom: 60px;
  }

  .container-grid.nav-video {
    max-width: 708px;
  }

  .container-grid.wrap-main-text-episodes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 40px;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.container-main-text-episodes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: start;
    margin-top: 40px;
    margin-right: 0;
    margin-left: 0;
    max-width: 700px;

    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.main-text-episodes {
    margin-top: 50px;
    margin-right: 40px;
  }

  .container-grid.main-text-partners {
    margin-right: 40px;
  }

  .container-grid.partners.wrap-main-text {
    -ms-flex-pack: center;
    -ms-flex-align: start;
    padding-right: 15px;
    padding-bottom: 50px;
    padding-left: 15px;

    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.wrap-video-ep1 {
    margin-right: 40px;
  }

  .container-grid.outer-wrap-sources {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    min-width: 0;
    max-width: 900px;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.container-main-text-about {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: start;
    margin-top: 40px;
    margin-right: 50px;
    margin-left: 15px;
    padding-right: 50px;
    min-width: 40%;
    max-width: 40%;

    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.background-about.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.container-main-text-about.main {
    -ms-flex-align: start;
    margin-top: 60px;
    margin-right: 0;
    margin-left: 0;
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 30px;
    min-width: 394px;
    max-width: 556px;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.container-main-text-about.ingress {
    margin-top: 60px;
    margin-right: 15px;
    padding-top: 0;
    padding-bottom: 30px;
    padding-left: 0;
    min-width: 320px;
    max-width: 372px;
  }

  .container-grid.card.card-flex-header {
    padding-right: 10px;
    padding-left: 10px;
    min-width: 100%;
    width: auto;

    justify-self: stretch;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
            align-self: auto;
  }

  .container-grid.wrap-card-and-logo {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-align: center;
    margin-bottom: 50px;
    max-width: none;
    width: auto;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.container-main-text.partners {
    min-width: 0;
    max-width: none;
    width: auto;
  }

  .container-grid.card.card-flex-header.card-partners {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    min-width: 0;
    max-width: none;
    border-top-right-radius: 20px;
  }

  .container-grid.wrap-all-partners {
    max-width: none;
  }

  .container-grid.wrap-logo {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
  }

  .container-grid.reversed.wrap-logo {
    border-radius: 20px 0 0 20px;
  }

  .container-grid.main-text-co-funded {
    margin-right: 40px;
  }

  .container-grid.reversed.theatre-forum.wrap-logo {
    border-radius: 0 0 20px 20px;
  }

  .container-grid.wrap-video-about {
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 0;
    min-width: 0;
    min-height: 0;
    max-width: 530px;
    max-height: 270px;
    width: 43%;
    height: auto;
  }

  .container-grid.background.video.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: start;
    background-image: url('../bgr014-D.jpg');
    background-attachment: scroll;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: cover;
    background-repeat: no-repeat;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    background-blend-mode: normal;
  }

  div.responsive-video.video-platform-1 {
    margin-top: 0;
    margin-left: 0;
    min-width: 10px;
    max-width: 100%;
  }

  .container-grid.wrap-quote {
    margin: 50px 50px 0 20px;
    padding-top: 0;
    padding-right: 0;
    max-width: 40%;
  }

  .container-grid.wrap-books {
    float: none;
    clear: none;
    margin-right: 40px;
    max-width: 44%;
    width: auto;
    height: auto;
  }

  .container-grid.other-books {
    margin-right: 40px;
    max-width: 44%;
    width: auto;
  }

  .container-grid.participants-one {
    float: none;
    clear: none;
    margin-right: 40px;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .container-grid.participants-four {
    margin-right: 40px;
    max-width: 44%;
    width: auto;
  }

  .container-grid.participants-three {
    float: none;
    clear: none;
    margin-right: 40px;
    max-width: 44%;
    width: auto;
    height: auto;
  }

  .container-grid.participants-two {
    float: none;
    clear: none;
    margin-right: 40px;
    max-width: 44%;
    width: auto;
    height: auto;
  }

  .container-grid.wrap-participants {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: center;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.participants-five {
    margin-right: 40px;
    max-width: 44%;
    width: auto;
  }

  dd.crew.negative-text {
    margin-bottom: 24px;
  }

  .container-grid.wrap-two-quotes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: start;
    margin-bottom: 40px;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.right.wrap-quote {
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 0;
  }

  .container-grid.left.wrap-quote {
    margin-top: 50px;
    margin-right: 0;
  }

  .container-grid.welcome.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.gdpr.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.overview.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.ep14.wrap-main-text-episodes {
    -ms-flex-align: start;
    padding-top: 20px;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.all-episodes {
    -ms-flex-pack: center;
    padding-right: 10px;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.creative-europe.wrap-logo {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
  }

  .container-grid.vgr-region.wrap-logo {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
  }

  .container-grid.agencia-andaluza.wrap-logo {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
  }

  .container-grid.container-main-text.overview-intro {
    -ms-flex-align: start;
    min-width: 0;
    max-width: none;
    width: auto;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.kat.overview.wrap-main-text {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.dropdown-index-mod.dropdown-menu {
    margin-top: -250px;
  }

  .container-grid.ep1.wrap-quote {
    margin-top: -300px;
  }

  .container-grid.container-main-text-about.ingress.register {
    margin-top: -400px;
  }

  .container-grid.about.main-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .container-grid.main-text-associate {
    margin-right: 40px;
  }

  .container-grid.fabryka.wrap-logo {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
}

@media screen and (min-width: 62rem) {
  /* Text Elements */

  p.paragraph.main-text {
    font-size: 16px;
  }

  p.paragraph.ingress.main-text_about.overview {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    max-width: 1200px;
  }

  dl.description-list.list-sources.middle {
    visibility: visible;
    max-width: 45%;
  }

  dl.description-list.four.list-sources {
    visibility: visible;
    max-width: 45%;
  }

  dl.description-list.five.list-sources {
    visibility: visible;
    max-width: 45%;
  }

  /* Interaction Elements */

  a.link-button.btn-1.btn-lecture {
    padding-left: 60px;
  }

  button.btn.button.navbar-toggler.navbar-toggler-icon {
    display: block;
    visibility: hidden;
  }

  .container-grid.collapse.navbar-collapse {
    -ms-flex-align: center;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.container-main-text-episodes {
    max-width: 700px;
  }

  .container-grid.partners.wrap-main-text {
    -ms-flex-pack: center;
    -ms-flex-align: center;
    padding-bottom: 50px;
    background-image: none;
    background-attachment: scroll;
    background-position: left top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 0 0;
    background-repeat: no-repeat;

    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    background-blend-mode: normal;
  }

  .container-grid.main-text-partners {
    margin-right: 0;
  }

  .container-grid.ep14.title-number {
    background-image: url('../14.png');
  }

  .container-grid.container-main-text-about {
    max-width: 700px;
  }

  .container-grid.card.card-flex-header {
    position: static;
    display: block;
  }

  .container-grid.wrap-card-and-logo {
    -ms-flex-align: center;
    width: auto;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.container-main-text.partners {
    max-width: 947px;
  }

  .container-grid.container-main-text-about.main {
    padding-right: 80px;
    max-width: 556px;
  }

  .container-grid.main-text-co-funded {
    margin-right: 0;
  }

  .container-grid.card.card-flex-header.card-partners {
    border-top-right-radius: 20px;
  }

  div.responsive-video.video-platform-1 {
    margin-left: 0;
  }

  .container-grid.background.video.wrap-main-text {
    -ms-flex-pack: center;
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.wrap-video-about {
    margin-right: 50px;
    max-width: 470px;
  }

  .container-grid.container-main-text-about.ingress {
    margin-right: 20px;
  }

  .container-grid.other-books {
    max-width: 30%;
  }

  .container-grid.wrap-books {
    max-width: 30%;
  }

  .container-grid.reference {
    margin-left: 0;
    max-width: 30%;
  }

  .container-grid.outer-wrap-sources {
    max-width: 900px;
  }

  .container-grid.participants-one {
    max-width: 100%;
  }

  .container-grid.participants-four {
    margin-right: 50px;
    max-width: 45%;
  }

  .container-grid.participants-three {
    max-width: 45%;
  }

  .container-grid.participants-two {
    margin-right: 50px;
    max-width: 45%;
  }

  .container-grid.participants-five {
    max-width: 45%;
  }

  .container-grid.wrap-two-quotes {
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.all-episodes {
    -ms-flex-pack: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.container-main-text.overview-intro {
    display: block;
    max-width: 952px;
  }

  .container-grid.main-text-co-funded.overview {
    margin-right: 40px;
  }

  .container-grid.kat.overview.wrap-main-text {
    background-position: center top;
  }

  .container-grid.container-main-text {
    max-width: 410px;
  }

  .container-grid.main-text-associate {
    margin-right: 0;
  }

  .container-grid.container-main-text-about.index.main {
    padding-right: 50px;
  }
}

@media screen and (min-width: 75rem) {
  /* Text Elements */

  h1 span.co-funded.heading-text-1 {
    color: #f2f3a5;
    font-size: 2.5rem;
  }

  h1 span.heading-text-3 {
    color: #f2f3a5;
  }

  h1 span.heading-text-4 {
    color: #f2f3a5;
    font-size: 2.5rem;
  }

  h3 span.h2 {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    line-height: 1.2;
  }

  h3 {
    font-size: 32px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    line-height: 38px;
  }

  p.paragraph.main-text-ep {
    margin-right: 40px;
  }

  p.paragraph.text-footer {
    background-color: transparent;
  }

  p.paragraph.ingress.main-text_about {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    min-width: 0;
    max-width: none;
  }

  p.paragraph span span.paragraph-text-6 {
    font-size: 1.5rem;
  }

  p.paragraph span.ingress-about.kat.negativ {
    font-size: 1.25rem;
    line-height: 1.7;
  }

  p.paragraph.index.main-text_about {
    text-align: left;
  }

  p.paragraph span.paragraph-text-6 {
    line-height: 1.5;
  }

  dl.description-list.list-sources {
    max-width: 30%;
  }

  dl.description-list.list-sources.middle {
    margin-right: 0;
  }

  dl.description-list.list-sources.right {
    margin-right: 0;
  }

  dl.description-list.four.list-sources {
    margin-right: 0;
  }

  dl.description-list.five.list-sources {
    margin-right: 0;
  }

  blockquote.quote-text {
    float: none;
    clear: none;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    padding-right: 50px;
    padding-bottom: 0;
    min-width: 0;
    max-width: none;
  }

  blockquote.quote-text.right {
    text-align: center;
  }

  blockquote.left.quote-text {
    text-align: center;
  }

  /* Interaction Elements */

  a.link-button.btn-lecture {
    background-attachment: scroll;
    background-position: left top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 0 0;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  a.link-button.btn.btn-secondary.dropdown-toggle {
    margin-top: 0;
  }

  a.link-button.btn-secondary-start.btn-start.dripdown-toggle-start {
    margin-top: 0;
  }

  a.link-button.btn.btn-left-1004 {
    max-width: 34px;
    max-height: 34px;
    width: 34px;
    height: 34px;
    background-image: url('../Arrow-L.png');
    background-attachment: scroll;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: contain;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  a.link-button.btn.btn-right-1004 {
    max-width: 34px;
    max-height: 34px;
    width: 34px;
    height: 34px;
    background-image: url('../Arrow-R.png');
    background-attachment: scroll;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: contain;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  a.link-button span.button-link-text-3 {
    color: #fff;
  }

  a.link-button.btn.btn-1002.evaluation {
    background-color: #714987;
  }

  a.link-button.btn.btn-1002:hover {
    /* background-color: #fff; ANDREAS */
    opacity: .8;
  }

  a.link-button.btn.btn-episodes:hover {
    color: #fff;
  }

  ul.list-container.navbar-nav {
    padding-left: 0;
  }

  li.list-item-container.nav-item {
    margin-left: 0;
    padding-left: 0;
  }

  .container-grid.wrap-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.con-video-bgr {
    margin-top: 20px;
    min-width: 100px;
    width: 100%;
  }

  .container-grid.con-video-size {
    margin-right: auto;
    margin-left: auto;
    max-width: 768px;
    max-height: 432px;
  }

  button.btn.button.navbar-toggler.navbar-toggler-icon {
    background-color: transparent;
  }

  .container-grid.title-size {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    max-width: 798px;
  }

  .container-grid.main-text {
    margin-top: 50px;
    margin-right: 40px;
    margin-bottom: 60px;
  }

  .container-grid.dropup {
    float: none;
    clear: none;
    text-align: center;
  }

  .container-grid.dropdown-menu {
    border-top-left-radius: 0 4px;
    border-top-right-radius: 0 4px;
    border-bottom-right-radius: 0 4px;
    border-bottom-left-radius: 0 4px;
  }

  .container-grid.wrap-main-text-episodes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.placeholder-hero {
    padding-left: 0;
    text-align: center;
  }

  .container-grid.main-text-episodes {
    margin-top: 50px;
    margin-right: 40px;
    margin-bottom: 16px;
  }

  .container-grid.main-text-partners {
    margin-right: 40px;
  }

  div.responsive-video.video-platform-1 {
    min-width: 10px;
    max-width: 100%;
    width: auto;
  }

  .container-grid.wrap-video-ep1 {
    float: none;
    clear: none;
    min-width: 300px;
  }

  .container-grid.div-headings.ep1 {
    margin-top: 30px;
  }

  .container-grid.ep13.title-number {
    background-image: url('../13.png');
  }

  .container-grid.background.wrap-main-text {
    background-color: rgba(208, 207, 204, .8);
  }

  .container-grid.container-main-text-about {
    margin-top: 50px;
    margin-right: 50px;
    margin-left: 30px;
    padding-right: 50px;
    padding-bottom: 30px;
  }

  .container-grid.container-main-text-about.main {
    padding: 30px 80px 15px 30px;
    background-color: #fff;
  }

  .container-grid.container-main-text.partners {
    max-width: 947px;
  }

  .container-grid.partners.wrap-main-text {
    -ms-flex-align: center;
    background-image: none;
    background-attachment: scroll;
    background-position: left top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 0 0;
    background-repeat: no-repeat;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    background-blend-mode: normal;
  }

  .container-grid.wrap-card-and-logo {
    margin-bottom: 50px;
  }

  .container-grid.co-funder.container-main-text.partners {
    margin-bottom: 0;
  }

  .container-grid.main-text-co-funded {
    margin-right: 40px;
  }

  .container-grid.agencia-andaluza.wrap-logo {
    background-image: url('../Spain-logo_sq.png');
    background-attachment: scroll;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 140% auto;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  .container-grid.reversed.theatre-forum.wrap-logo {
    border-radius: 0 0 20px 20px;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 130% auto;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  .container-grid.creative-europe.wrap-logo {
    background-image: url('../EU-logga_600.png');
    background-attachment: scroll;
    background-position: center center;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: 90% auto;
    background-repeat: no-repeat;

    background-blend-mode: normal;
  }

  .container-grid.background.video.wrap-main-text {
    -ms-flex-pack: center;
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.container-main-text {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: start;
    min-width: 3px;
    width: 50%;

    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.wrap-video-about {
    margin-right: 50px;
    margin-left: 0;
    min-width: 0;
    max-height: 270px;
    height: auto;
  }

  .container-grid.container-main-text-about.ingress {
    max-width: 376px;
  }

  .container-grid.div-headings {
    display: block;
  }

  .container-grid.wrap-quote {
    margin-right: 50px;
    margin-left: 20px;
    padding-right: 0;
    padding-bottom: 30px;
    min-width: 40%;
    max-width: 40%;
  }

  .container-grid.outer-wrap-sources {
    max-width: 900px;
  }

  .container-grid.participants-one {
    max-width: 100%;
    width: 100%;
  }

  .container-grid.participants-four {
    max-width: 46%;
  }

  .container-grid.participants-three {
    max-width: 46%;
    width: 46%;
  }

  .container-grid.participants-two {
    max-width: 46%;
  }

  .container-grid.participants-five {
    max-width: 46%;
  }

  .container-grid.wrap-two-quotes {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: start;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.right.wrap-quote {
    margin-right: 20px;
    margin-left: 0;
    width: 40%;
  }

  .container-grid.left.wrap-quote {
    width: 40%;
  }

  .container-grid.all-episodes {
    -ms-flex-pack: center;
    -ms-flex-align: start;
    padding-right: 10px;
    max-width: none;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.container-main-text.overview-intro {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    margin-right: 0;
    margin-left: 0;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.kat.overview.wrap-main-text {
    -ms-flex-pack: center;
    -ms-flex-align: center;
    margin-right: 0;
    background-image: url('../bgr014-D.jpg');
    background-attachment: scroll;
    background-position: center top;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: auto 220%;
    background-repeat: no-repeat;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    background-blend-mode: normal;
  }

  .container-grid.wrap-pic-header {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -ms-flex-align: center;
    min-width: 100px;
    width: 100%;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.kat-wrap {
    display: block;
    margin-right: 30px;
  }

  .container-grid.main-text-co-funded.overview {
    margin-right: 40px;
  }

  div.responsive-picture.logo-upper.picture-1 {
    margin-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rule.rule-partners hr {
    border-top-color: #f2f3a5;
  }

  .container-grid.con-video-bgr.ep14 {
    background-attachment: fixed;
  }

  .container-grid.main-text-associate {
    margin-top: 50px;
    margin-right: 40px;
  }

  .container-grid.vgr-region.wrap-logo {
    background-image: url('../VGR_logo_sq.png');
  }

  .container-grid.fabryka.wrap-logo {
    background-image: url('../ART_IN_FS_eng_black.png');
    background-size: contain;
  }

  .container-grid.container-main-text-about.index.main {
    text-align: left;
  }

  .container-grid.dropdown-menu:hover {
    margin-bottom: 0;
  }
}
